import React from 'react';
import { InProcessOrderList } from 'ui/components/partials/Order/InProccessOrderList';

export const InProcessPage = () => {
  return (
    <>
      <InProcessOrderList />
    </>
  );
};
