import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChaletApiResponse } from 'types/ChaletApiResponse';
import { Order, ProductVariation, VariationOption } from 'types/Order';
import { Product } from 'types/Product';
import { ProductCategory } from 'types/ProductCategory';
import { ProductType } from 'types/ProductTypes';
import { Menu, Shop } from 'types/Shop';
import { ShopMenuCategory } from 'types/ShopMenuCategory';
import { Schedule } from 'types/Shop';
import { ShopMenus } from 'types/ShopMenu';
import { User } from 'types/User';

const chaletApi = createApi({
  reducerPath: 'chaletApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders(headers, api) {
      const store: any = api.getState();
      headers.set('Authorization', `Bearer ${store.auth.token}`);
    }
  }),
  tagTypes: ['orders', 'shop', 'shops', 'shop-menus', 'shop-products', 'shop-product', 'complete-menu', 'user'],
  
  endpoints: (builder) => ({
    login: builder.query({
      query: ({ email, password }) => ({
        method: 'POST',
        url: '/auth/login',
        body: { email, password }
      })
    }),
    loginWithToken: builder.query({
      query: (token) => ({
        method: 'POST',
        url: '/auth/login/token',
        body: { token }
      })
    }),
    getActiveOrders: builder.query<Order[], number>({
      query: (shopId) => ({
        method: 'GET',
        url: '/orders/active',
        params: { shopId, withorderProcesses: true }
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'orders' as const, id })), 'orders']
          : ['orders'],
      transformResponse: (response: ChaletApiResponse<Order[]>, meta, arg) => response.data
    }),
    getOrdersInRange: builder.query({
      query: ({ from, to, shopId }) => ({
        method: 'GET',
        url: '/orders/all-in-dates',
        params: { from, to, shopId }
      }),
      transformResponse: (response: ChaletApiResponse<Order[]>, meta, arg) => response.data
    }),
    getOrder: builder.query({
      query: (orderId) => ({
        method: 'GET',
        url: `/orders/${orderId}`
      })
    }),
    completeOrderStep: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/orders/${id}/complete-step/`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['orders'],
      transformResponse: (response: any, meta, arg) => response.data,
      transformErrorResponse: (response: any, meta, arg) => {
        const data = response.data;
        return data.message;
      }
    }),
    getUserById: builder.query({
      query: (userId) => ({
        method: 'GET',
        url: `/users/${userId}`
      }),
      providesTags: ['user']
    }),
    getShopById: builder.query<Shop, number>({
      query: (shopId) => ({
        method: 'GET',
        url: `/shops/${shopId}`
      }),
      providesTags: ['shop'],
      transformResponse: (response: ChaletApiResponse<Shop>, meta, arg) => response.data
    }),
    getAllShops: builder.query({
      query: () => ({
        method: 'GET',
        url: `/shops/all`
      }),
      providesTags: ['shops']
    }),
    getShopMenusForShop: builder.query<Menu[], number>({
      query: (shopId) => ({
        method: 'GET',
        url: `/shop-menus/forShop/${shopId}`
      }),
      providesTags: ['shop-menus'],
      transformResponse: (response: ChaletApiResponse<Menu[]>, meta, arg) => response.data
    }),

    saveMenuCategory: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/shop-menu-categories`,
        body
      }),
      invalidatesTags: ['complete-menu']
    }),
    getProductsForCategoryMenu: builder.query<Product[], number>({
      query: (categoryMenuId) => ({
        method: 'GET',
        url: `/shop-products/forCategoryMenu/${categoryMenuId}`
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: 'shop-products' as const, id })),
              { type: 'shop-products', id: 'LIST' }
            ]
          : [{ type: 'shop-products', id: 'LIST' }];
      },
      transformResponse: (response: ChaletApiResponse<Product[]>, meta, arg) => {
        const sortedProducts = response.data.sort((a, b) => a.id - b.id);
        return sortedProducts;
      }
    }),
    getProductCategories: builder.query<ProductCategory[], ProductType | undefined>({
      query: (type) => ({
        method: 'GET',
        url: `/product-categories?type=` + type
      }),
      transformResponse: (response: ChaletApiResponse<ProductCategory[]>, meta, arg) =>
        response.data
    }),
    saveShopProduct: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/shop-products`,
        body
      }),
      invalidatesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data
    }),
    getProductById: builder.query<Product, number>({
      query: (id) => ({
        method: 'GET',
        url: `/shop-products/${id}`
      }),
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data,
      providesTags: (result) => {
        return result?.id ? [{ type: 'shop-product', id: result.id }] : ['shop-product'];
      }
    }),
    editShopProduct: builder.mutation({
      query: ({ productId, body }) => ({
        method: 'PUT',
        url: `/shop-products/${productId}`,
        body
      }),
      invalidatesTags: ['complete-menu', 'shop-product'],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data
    }),
    saveProductVariation: builder.mutation<ProductVariation, Partial<ProductVariation>>({
      query: (body) => ({
        method: 'POST',
        url: `/product-variations`,
        body
      }),
      invalidatesTags: ['shop-product'],
      transformResponse: (response: ChaletApiResponse<ProductVariation>, meta, arg) => response.data
    }),
    saveProductVariationOption: builder.mutation<VariationOption, Partial<VariationOption>>({
      query: (body) => ({
        method: 'POST',
        url: `/product-variation-options`,
        body
      }),
      invalidatesTags: (result) => {
        return ['shop-product'];
      },
      transformResponse: (response: ChaletApiResponse<VariationOption>, meta, arg) => response.data
    }),
    editShopSchedule: builder.mutation<Schedule, Partial<Schedule>>({
      query: (body) => ({
        method: 'PUT',
        url: `/shops/update-schedule`,
        body
      }),
      invalidatesTags: ['shop'],
      transformResponse: (response: ChaletApiResponse<Schedule>, meta, arg) => response.data
    }),

    updateShop: builder.mutation({
      query: ({ shopId, body }) => ({
        method: 'PUT',
        url: `/shops/${shopId}`,
        body
      }),
      invalidatesTags: ['shop'],
      transformResponse: (response: ChaletApiResponse<Shop>, meta, arg) => response.data
    }),
    getCompleteMenu: builder.query({
      query: (shopId) => ({
        method: 'GET',
        url: `/shop-menus/completeMenu/${shopId}`
      }),
      providesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<[ShopMenus]>) => {
        // Ordenar menuCategories por correlative de menor a mayor
        const sortedMenus = response.data.map((menu) => ({
          ...menu,
          menuCategories: menu.menuCategories.sort((a, b) => a.correlative - b.correlative)
        }));

        return sortedMenus;
      }
    }),
    updateShopMenuCategory: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PUT',
        url: `/shop-menu-categories/${id}`,
        body
      }),
      invalidatesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<ShopMenuCategory>) => response.data
    }),
    deleteShopProduct: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/shop-products/${id}`
      }),
      invalidatesTags: ['complete-menu']
    }),
    updateUser: builder.mutation({
      query: ({ userId, body }) => ({
        method: 'PUT',
        url: `/users/${userId}`,
        body
      }),
      invalidatesTags: ['user']
    }),
    updateIsAvailable: builder.mutation({
      query: (body) => ({
          method: 'PUT',
          url: `/shop-products/update/isAvailable`,
          body
        }),
        invalidatesTags: ['complete-menu']
      }),
      getDashboard: builder.query({
        query: ({id, from, to}) => ({
          method: 'GET', 
          url: `shops/dashboard/${id}`,
          params: { from, to }
        })
      }),
      updateProductVariation: builder.mutation({
        query: ({ id, body }) => ({
          method: 'PUT',
          url: `/product-variations/${id}`,
          body
        }),
        invalidatesTags: ['shop-product']
      }),
      deleteProductVariation: builder.mutation({
        query: (id) => ({
          method: 'DELETE',
          url: `/product-variations/${id}`
        }),
        invalidatesTags: ['shop-product']
      }),
      updateProductVariationOption: builder.mutation({
        query: ({ id, body }) => ({
          method: 'PUT',
          url: `/product-variation-options/${id}`,
          body
        }),
        invalidatesTags: ['shop-product']
      }),
      deleteProductVariationOption: builder.mutation({
        query: (id) => ({
          method: 'DELETE',
          url: `/product-variation-options/${id}`
        }),
        invalidatesTags: ['shop-product']
      }),
  })
});

export default chaletApi;
