import { Avatar, Button, Grid, Typography, styled } from '@mui/material';

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: '4px solid #ffffff',
  width: '125px',
  height: '125px',
  display: 'flex',
  marginTop: '-65px',
  [theme.breakpoints.down('sm')]: {
    width: '90px',
    height: '90px',
    marginTop: '-50px'
  }
}));

export const CustomGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',

  justifyItems: 'center'
  /*   [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }, */
}));

export const imgStyle: any = {
  aspectRatio: '16/9',
  objectFit: 'cover',
  maxWidth: '100%',
  maxHeight: '100%'
};
