import { Square } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Order } from 'types/Order';
import { toCurrencyFormat } from 'utils/formatter';
import { calculateOrderStatistics } from 'utils/statistics';

type StatisticsOrdersProps = {
  orders: Order[];
};

export const StatisticsOrders: React.FC<StatisticsOrdersProps> = ({ orders }) => {
  const statistics = calculateOrderStatistics(orders);
  console.log(statistics);

  return (
    <Grid container>
      <Grid item xs={6} md={6}>
        <Box display={'flex'} justifyContent={'center'}>
          <Box flexDirection={'column'}>
            <Typography variant="h4" mb={1} textAlign={'center'}>
              {statistics.totalOrdersCompleted}
            </Typography>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Square color="success" />
              <Typography textAlign={'center'} fontSize={20}>
                Órdenes Completadas
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box display={'flex'} justifyContent={'center'}>
          <Box flexDirection={'column'}>
            <Typography variant="h4" mb={1} textAlign={'center'}>
              {statistics.totalOrdersCanceled}
            </Typography>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Square color="error" />
              <Typography textAlign={'center'} fontSize={20}>
                Órdenes Canceladas
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} mt={5}>
        <Box display={'flex'} justifyContent={'center'}>
          <Stack direction={'column'}>
            <Typography textAlign={'center'} variant="h4">
              Ventas totales: {toCurrencyFormat(statistics.totalSales)}
            </Typography>
            <Typography textAlign={'center'} variant="h4">
              Perdidas totales: {toCurrencyFormat(statistics.losses)}
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
