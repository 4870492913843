import { createSlice } from '@reduxjs/toolkit';
import chaletApi from '../api/chaletApi';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

type AuthState = {
  isLoggedIn: boolean;
  userId: number | null;
  token: string | null;
};

const initialState: AuthState = {
  isLoggedIn: false,
  userId: null,
  token: null
};

const authPersistConfig = {
  key: 'auth',
  storage: storage
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.userId = null;
      state.isLoggedIn = false;
      state.token = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(chaletApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload.success) {
        state.isLoggedIn = true;
        state.token = payload.data.token;
        state.userId = payload.data.user.id;
      }
    });
  }
});

const persistedUserAuthSlice = persistReducer(authPersistConfig, authSlice.reducer);

export const { logOut } = authSlice.actions;

export default persistedUserAuthSlice;
