import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  styled
} from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import chaletApi from '../../../../../api/chaletApi';
import { closeAddProductModal } from '../../../../../redux/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { ProductType } from 'types/ProductTypes';
import { CloudUpload } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

type AddProductModalProps = {
  categoryId: number;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre del producto es requerido'),
  description: Yup.string()
    .required('La descripción es requerida')
    .max(240, 'La descripción debe tener como máximo 240 caracteres'),
  price: Yup.number()
    .typeError('El precio debe ser un número')
    .positive('El precio debe ser un número positivo')
    .required('El precio es requerido'),
  category: Yup.number().required('Selecciona una categoría')
});


export const AddProductModal: React.FC<AddProductModalProps> = ({ categoryId }) => {
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { isAddProductModalOpen } = useAppSelector((s) => s.modalSlice);
  const { user, selectedShop } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const [postProduct, result] = chaletApi.useSaveShopProductMutation();
  const { data: categoriesData } = chaletApi.useGetProductCategoriesQuery(ProductType.Food);

  const handleClose = useCallback(() => {
    dispatch(closeAddProductModal());
  }, [dispatch]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const allowedExtensions = ['.jpeg', '.jpg', '.png'];
      const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();

      if (
        file.type.startsWith('image/') &&
        fileExtension &&
        allowedExtensions.includes(fileExtension)
      ) {
        setImage(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        setImage(null);
        setPreviewUrl(null);
        alert('Por favor, selecciona una imagen válida (JPEG, JPG o PNG).');
      }
    }
  };

  const saveProduct = (data: any) => {
    console.log({ data });
    try {
      if (selectedShop && image) {
        const formData = new FormData();
        formData.append('shopMenuCategoryId', String(categoryId));
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('price', data.price);
        formData.append('categoryId', data.category);
        formData.append('shopId', String(selectedShop.id));
        formData.append('productTypeId', String(selectedShop.productTypeId));
        formData.append('isDeleted', 'false');
        formData.append('image', image);

        toast.promise(postProduct(formData), {
          loading: 'Guardando...',
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>
        });
      } else if (user) {
        const shop = user.works[0].shop;
        const productData = {
          shopMenuCategoryId: categoryId,
          name: data.name,
          description: data.description,
          price: Number(data.price),
          categoryId: Number(data.category),
          shopId: shop.id,
          productTypeId: 1,
          isDeleted: false
        };

        toast.promise(postProduct(productData), {
          loading: 'Guardando...',
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.errors.forEach((errorMessage: string) => toast.error(errorMessage));
      } else {
        toast.error('Error guardando producto');
      }
    }
  };

  useEffect(() => {
    if (result.isError) {
      toast('Error guardando menu');
    }
    if (result.isSuccess) {
      handleClose();
      setImage(null);
      setPreviewUrl(null);
    }
  }, [result, handleClose]);

  return (
    <Dialog scroll='paper' open={isAddProductModalOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Agregar producto</DialogTitle>
      <DialogContent dividers>
        <Box
          margin={2}
          display="flex"
          flexDirection="column">
          <TextField
            sx={{ mt: 2 }}
            placeholder="Nombre"
            type="text"
            error={!!errors.name}
            helperText={errors.name?.message}
            {...register('name')}
          />

          <TextField
            sx={{ mt: 2 }}
            placeholder="Descripción"
            multiline
            rows={3}
            error={!!errors.description}
            helperText={errors.description?.message}
            {...register('description')}
          />

          <TextField
            sx={{ mt: 2 }}
            placeholder="Precio"
            type="text"
            defaultValue={0}
            error={!!errors.price}
            helperText={errors.price?.message}
            {...register('price')}
          />

          {categoriesData && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="category">Categoria</InputLabel>
              <Controller
                name="category"
                control={control}
                defaultValue={1}
                render={({ field }) => (
                  <Select label="Categoria" {...field}>
                    {categoriesData.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              <FormHelperText>{errors.category && errors.category.message}</FormHelperText>
            </FormControl>
          )}

          <Button
            sx={{ mt: 2 }}
            component="label"
            variant="contained"
            startIcon={<CloudUpload />}>
            Agregar Foto
            <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
          </Button>

          {previewUrl && (
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </Box>
          )}

        </Box>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSubmit(saveProduct)}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
