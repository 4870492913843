import { Order } from 'types/Order';

export const calculateOrderStatistics = (orders: Order[]) => {
  let totalOrdersCanceled = 0;
  let totalOrdersCompleted = 0;
  let totalSales = 0;
  let losses = 0;

  orders.forEach((order) => {
    if (order.isCanceled) {
      totalOrdersCanceled++;

      losses += parseFloat(order.productsTotal);
    } else if (order.isCompleted) {
      totalOrdersCompleted++;

      totalSales += parseFloat(order.productsTotal);
    }
  });

  return {
    totalOrdersCanceled,
    totalOrdersCompleted,
    totalSales,
    losses
  };
};
