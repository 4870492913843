import React from 'react';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material';

type ThemeProp = {
  children: JSX.Element;
};

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    white: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }
}

interface CustomThemeOptions extends ThemeOptions {
  space?: {
    sm: number;
    md: number;
    lg: number;
  };

}

const theme = createTheme({
  space: {
    sm: 8,
    md: 16,
    lg: 24,
  },
  fontSizes: {
    sm: 14,
    md: 14,
    lg: 16,
    xl: 24,
    xxl: 32,
  },
  palette: {
    primary: {
      main: '#EF3D32',
      dark: '#EF3D32',
      light: '#EF3D32',
    },
    secondary: {
      main: '#01869E',
    },
    background: {
      default: '#EEEEEE',
      paper: '#FFFFFF',
    },
    black: {
      main: '#020202',
    },
    white: {
      main: '#FFFFFF',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F4F4F5',
      200: '#E4E4E7',
      300: '#D4D4D8',
      400: '#A1A1AA',
      500: '#71717A',
      600: '#52525B',
      700: '#3F3F46',
      800: '#27272A',
      900: '#18181B',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: { textTransform: 'none' },
      },
    },
    MuiContainer: {
      defaultProps: {
        style: { padding: 0 },
      },
    },
    MuiTab: {
      defaultProps: { style: { textTransform: 'none' } },
    },
  },
} as CustomThemeOptions);

export const ThemeConfig: React.FC<ThemeProp> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
