import { Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import chaletApi from '../../../api/chaletApi';
import { toast } from 'react-hot-toast';
import { ChaletLogo } from 'ui/components/common';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, result] = chaletApi.useLazyLoginQuery();

  useEffect(() => {
    if (result.isError) {
      toast('Error iniciando sesión');
    }
  }, [result]);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      login({ email, password });
    },
    [email, password, login]
  );
  return (
    <Grid container justifyContent="center" bgcolor="background.default" minHeight="100vh">
      <Grid item sm={12} md={6}>
        <Card sx={{ p: 2, mt: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack gap={1}>
              <ChaletLogo />
              <Typography variant="h2" fontWeight={500}>
                El Chalet
              </Typography>
              <Typography variant="h4" fontWeight={500}>
                Panel administrativo de comercio
              </Typography>
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                sx={{ mt: 1 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                sx={{ mt: 1 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button variant="contained" type="submit" sx={{ mt: 1 }}>
                Iniciar Sesión
              </Button>

            </Stack>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

