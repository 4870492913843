import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import chaletApi from '../../../../api/chaletApi';
import {useAppSelector } from 'redux/hook';
import { AddCategoryModal } from 'ui/components/partials/Admin/AddCategoryModal';
import { CategoriesTable } from 'ui/components/partials/Admin/CategoriesTable';

export const AdminMenu = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { user } = useAppSelector((state) => state.user);

  const [getCompleteMenu, completeMenu] = chaletApi.useLazyGetCompleteMenuQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (user) {
      const userShop = user.works[0].shop.id;
      getCompleteMenu(userShop);
    }
  }, [user]);

  if (completeMenu.data) {
    const currentMenu = completeMenu.data[currentTab];

    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 2 }}>
          <Tabs value={currentTab} onChange={handleChange}>
            {completeMenu.data.map((menu) => {
              return <Tab key={menu.name} label={menu.name} />;
            })}
          </Tabs>
        </Box>
        <CategoriesTable data={completeMenu.data[currentTab].menuCategories} />
        {currentMenu && (
          <AddCategoryModal
            selectedMenu={currentMenu}
            correlative={currentMenu.menuCategories.length + 1}
          />
        )}
      </>
    );
  }
  return null;
};
