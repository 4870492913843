import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleDrawer } from '../../../../redux/shopDrawer';
import { logOut } from '../../../../redux/authSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { Shop } from 'types/Shop';

export const ShopAdminHeader = () => {
  const [shop, setShop] = React.useState<Shop | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((s) => s.shopAdminDrawer);
  const { user } = useAppSelector((s) => s.user);

  React.useEffect(() => {
    if (user) {
      const userShop = user.works[0].shop;
      setShop(userShop);
    }
  }, [user]);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfile = () => {
    window.location.href = '/perfil';
  };

  const logout = () => {
    try {
      dispatch(logOut());
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AppBar position="static">
      <Box sx={{ marginLeft: isOpen ? 240 / 8 : 0 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => dispatch(toggleDrawer())}>
            <MenuIcon />
          </IconButton>
          {shop && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {shop.name}
            </Typography>
          )}

          <Avatar sx={{ backgroundColor: '#222222' }}>{user?.name.charAt(0)}</Avatar>
          <Button color="inherit" onClick={handleClick}>
            {user?.name}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            <MenuItem onClick={goToProfile}>Mi Perfil</MenuItem>
            <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
          </Menu>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
