import React from 'react';
import { Avatar, Paper, Typography, Box, Stack, useTheme } from '@mui/material';
import { amber, deepPurple, green } from '@mui/material/colors';


type props = {
  products: [{
    name: string; 
    quantity: number;
  }];
};



export const BestProductsCards: React.FC<props> = ({ products }) => {

  const {palette} = useTheme();

  const color = [palette.primary.main, palette.secondary.main, amber[800], deepPurple[800], green[800],];

  return (
    <Paper
    elevation={3}
      sx={{
        borderRadius: 8,
        padding: '24px',
        border: '1px solid #e0e0e0',
        color: '#000',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.5rem',
            fontWeight: '600',
            marginBottom: '8px',
            textAlign: 'center'
          }}>
          Productos más vendidos
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
        {products.map((product, index) => (
          <Stack key={index} direction={'row'} alignItems={'center'} spacing={2} mt={1}>
            <Avatar sx={{ bgcolor: color[index] }}>{index + 1}</Avatar>
            <Box>      <Typography>{product.name}</Typography>
            <Typography variant='subtitle2' color={'secondary'}>{`Cantidad: ${product.quantity}`}</Typography></Box>
      
          </Stack>
        ))}
      </Box>
    </Paper>
  );
};
