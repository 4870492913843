import { Button, Card, Input, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import chaletApi from '../../../../../api/chaletApi';
import { closeAddCategoryModal } from '../../../../../redux/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { ShopMenus } from 'types/ShopMenu';

type AddCategoryModalProps = {
  selectedMenu: ShopMenus;
  correlative: number;
};

export const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  selectedMenu,
  correlative
}) => {
  const [name, setName] = useState('');
  const { isAddCategoryModalOpen } = useAppSelector((state) => state.modalSlice);
  const [postMenuCategory] = chaletApi.useSaveMenuCategoryMutation();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeAddCategoryModal());
  }, [dispatch]);

  const saveCategory = useCallback(async () => {
    try {
      const categoryData = {
        shopMenuId: selectedMenu.id,
        name,
        correlative
      };

      const payload = await postMenuCategory(categoryData).unwrap();

      if (payload.success) {
        toast.success('Guardado con éxito');
        handleClose();
      } else {
        toast.error('Ha ocurrido un error');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error');
    }
  }, [selectedMenu, name, postMenuCategory, correlative, handleClose]);

  return (
    <Modal open={isAddCategoryModalOpen} onClose={handleClose}>
      <Box margin={8}>
        <Card sx={{ width: '50%', margin: '0 auto' }}>
          <Box margin={2} display="flex" flexDirection="column">
            <Typography variant="h6" component="h2">
              Agregar categoría
            </Typography>
            <Box margin={1} />
            <Input
              placeholder="Nombre de categoría"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box margin={1} />
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Box margin={1} />
            <Button title="Guardar" variant="contained" onClick={saveCategory}>
              Guardar
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};
