import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';

import { formatCustomDate, toCurrencyFormat } from '../../../../../utils/formatter';
import { Order } from 'types/Order';
import { OrderDetailsView } from '../OrderDetailsView';
import { ExpandMore } from '@mui/icons-material';

type OrderHistoryItemProps = {
  order: Order;
};

export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({ order }) => {
  console.log(order);
  const { palette } = useTheme();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const handleAccordionToggle = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  return (
    <Card sx={{ margin: 1, display: 'inline-flex', position: 'relative' }} elevation={4}>
      <Box
        sx={{
          width: '300px',
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          m: 2,
          overflow: 'hidden',
          maxHeight: isAccordionExpanded ? 'none' : '500px'
        }}>
        <Box>
          <Typography textAlign="left" variant="subtitle2" color="primary">
            {formatCustomDate(order.createdAt)}
          </Typography>
        </Box>

        <Box display="flex" mt={1}>
          <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={1}>
            <Typography variant="subtitle2">Estado:</Typography>
            {order.isCompleted && (
              <Chip
                label={'Completado'}
                sx={{ backgroundColor: '#0e766e', color: palette.white.main }}
              />
            )}
            {order.isCanceled && (
              <Chip
                label={'Cancelado'}
                sx={{ backgroundColor: '#C0392B', color: palette.white.main }}
              />
            )}
          </Stack>
        </Box>

        <Box mt={1} flex={1}>
          <Accordion expanded={isAccordionExpanded} onChange={handleAccordionToggle}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography variant="subtitle2">Detalles de la orden</Typography>
            </AccordionSummary>
            <Box flex={1} ml={1} mr={1}>
              <OrderDetailsView details={order.details} />
              <Stack direction={'row'} alignItems={'center'}>
                <Typography textAlign={'left'} flex={1}>
                  Total:
                </Typography>
                <Typography>{toCurrencyFormat(parseFloat(order.productsTotal))}</Typography>
              </Stack>
            </Box>
          </Accordion>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          backgroundColor: palette.primary.main,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: '0px  7px'
        }}>
        <Typography color={palette.white.main} fontWeight={600}>
          {order.id}
        </Typography>
      </Box>
    </Card>
  );
};
