import React from 'react';
import { Order } from 'types/Order';
import { calculateOrderStatistics } from 'utils/statistics';
import { Typography, Paper, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

type StatisticsOrdersProps = {
  orders: Order[];
};

export const ChartStatistics: React.FC<StatisticsOrdersProps> = ({ orders }) => {
  const { totalOrdersCompleted, totalOrdersCanceled, totalSales, losses } =
    calculateOrderStatistics(orders);

  const OrdersData = [
    {
      category: 'Ordenes',
      completadas: totalOrdersCompleted,
      canceladas: totalOrdersCanceled
    }
  ];

  const SalesData = [{ category: 'Ventas', ganancias: totalSales, perdidas: losses }];

  return (
   
      <Paper
        sx={{
          borderRadius: 8,
          border: '1px solid #e0e0e0',
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '8px', textAlign: 'center' }}>
          Ordenes y Ventas
        </Typography>
        <Grid container spacing={3} alignItems="stretch" justifyContent="center">
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart data={OrdersData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} />
                <Bar dataKey="completadas" fill="#1976D2" barSize={50} />
                <Bar dataKey="canceladas" fill="#EF3D32" barSize={50}/>
              </BarChart>
            </ResponsiveContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart data={SalesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} />
                <Bar dataKey="ganancias" fill="#1976D2" barSize={50} />
                <Bar dataKey="perdidas" fill="#EF3D32" barSize={50} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>
    
  );
};
