import React, { useState, useEffect } from 'react';
import { formatTime } from 'utils/formatter';
import { daysOfTheWeek } from 'utils/date';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  TextField
} from '@mui/material';
import { Schedule, Shop } from '../../../../../types/Shop';
import chaletApi from 'api/chaletApi';
import dayjs, { Dayjs } from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers';

type ScheduleTableProps = {
  schedule: Schedule[];
  shop: Shop;
};

type UpdateScheduleModalProps = {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
  schedule: {
    id: number;
    day: number;
    opens: string;
    closes: string;
    closed: boolean;
  };
};

const UpdateScheduleModal: React.FC<UpdateScheduleModalProps> = ({
  isOpen,
  onClose,
  schedule,
  shop
}) => {
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [editShopSchedule, result] = chaletApi.useEditShopScheduleMutation();

  useEffect(() => {
    if (schedule) {
      const open = dayjs(schedule.opens, 'hh:mm A');
      const close = dayjs(schedule.closes, 'hh:mm A');
      const formattedOpen = open.format('YYYY-MM-DDTHH:mm');
      const formattedClose = close.format('YYYY-MM-DDTHH:mm');

      setStart(dayjs(formattedOpen));
      setEnd(dayjs(formattedClose));
    }
  }, [schedule]);

  const updateSchedule = () => {
    console.log(start);
    const adjustedStartDate = dayjs(start)
      .set('year', 1970)
      .set('month', 0)
      .set('date', 1)
      .utcOffset(0, true);
    const startDate = adjustedStartDate.format('YYYY-MM-DD HH:mm:ss.SSS ZZ');

    const adjustedEndDate = dayjs(end)
      .set('year', 1970)
      .set('month', 0)
      .set('date', 1)
      .utcOffset(0, true);
    const endDate = adjustedEndDate.format('YYYY-MM-DD HH:mm:ss.SSS ZZ');

    const data: any = {
      shopId: shop.id,
      days: [
        {
          id: schedule.id,
          day: schedule.day,
          startDate,
          endDate
        }
      ]
    };

    editShopSchedule(data);
  };

  useEffect(() => {
    if (result.isSuccess) {
      onClose();
      setStart(null);
      setEnd(null);
    }
  }, [result.isSuccess]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 8,
          textAlign: 'center'
        }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Actualizar Horario
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 1 }}>
          Abre
        </Typography>
        <TimePicker
          value={start}
          onChange={(newValue) => setStart(newValue)}
          sx={{ width: '100%' }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock
          }}
        />
        <Typography color="text.secondary" sx={{ mb: 1 }}>
          Cierra
        </Typography>
        <TimePicker
          value={end}
          onChange={(newValue) => setEnd(newValue)}
          sx={{ width: '100%' }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock
          }}
        />
        <Button variant="contained" color="primary" onClick={updateSchedule} sx={{ mr: 2 }}>
          Actualizar
        </Button>
        <Button onClick={onClose}>Cerrar</Button>
      </Box>
    </Modal>
  );
};

export const ScheduleTable: React.FC<ScheduleTableProps> = ({ schedule, shop }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState<any>();
  const [editShopSchedule, result] = chaletApi.useEditShopScheduleMutation();

  const handleOpenModal = (day: any) => {
    const dayOfWeek = sch.find((item) => item.day === day);
    setCurrentDay(dayOfWeek);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateIsClosedCheckbox = (schedule: any) => {
    const data: any = {
      shopId: shop.id,
      days: [
        {
          day: schedule.day,
          closed: !schedule.closed
        }
      ]
    };
    editShopSchedule(data);
  };

  const sch = schedule.map(({ id, day, start, end, closed }) => {
    const startFormat = formatTime(start);
    const endFormat = formatTime(end);
    return {
      id,
      day,
      opens: startFormat,
      closes: endFormat,
      closed: closed
    };
  });

  const SortedSchedule = sch.sort((a, b) => {
    return a.day - b.day;
  });

  return (
    <TableContainer sx={{ width: { xs: '100vw', md: '600px' } }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Día</TableCell>
            <TableCell align="center">Abre</TableCell>
            <TableCell align="center">Cierra</TableCell>
            <TableCell align="center">Cerrado</TableCell>
            <TableCell align="center">Actualizar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {SortedSchedule.map((schedule) => (
            <TableRow key={schedule.day}>
              <TableCell align="center" sx={{ fontSize: '0.8rem' }}>
                {daysOfTheWeek[schedule.day]}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.8rem' }}>
                {schedule.opens}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.8rem' }}>
                {schedule.closes}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.8rem' }}>
                <Checkbox
                  checked={schedule.closed}
                  onClick={() => updateIsClosedCheckbox(schedule)}
                />
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.8rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal(schedule.day)}>
                  <EditIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {sch && currentDay && (
        <UpdateScheduleModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          schedule={currentDay}
          shop={shop}
        />
      )}
    </TableContainer>
  );
};
