import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import chaletApi from '../../../../../api/chaletApi';
import { useAppSelector } from 'redux/hook';
import { OrderHistoryItem } from '../OrderHistoryItem/OrderHistoryItem';
import { Order } from 'types/Order';

type OrdersInRangeProps = {
  orders: Order[];
};
enum OrderFilter {
  All = 'All',
  Completed = 'Completed',
  Canceled = 'Canceled'
}

export const OrdersInRange: React.FC<OrdersInRangeProps> = ({ orders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<OrderFilter>(OrderFilter.All);
  const itemsPerPage = 15;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleFilterChange = (newFilter: OrderFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const filteredOrders = useMemo(() => {
    let filterOrders = orders || [];
    if (filter === OrderFilter.Completed) {
      filterOrders = filterOrders.filter((order) => order.isCompleted);
    } else if (filter === OrderFilter.Canceled) {
      filterOrders = filterOrders.filter((order) => order.isCanceled);
    }
    return filterOrders;
  }, [orders, filter]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const ordersToDisplay = filteredOrders.slice(startIndex, endIndex);

  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'center'} mt={1}>
        <Typography>Ordenar por:</Typography>
        <Select
          value={filter}
          onChange={(event) => handleFilterChange(event.target.value as OrderFilter)}
          variant="standard">
          <MenuItem value={OrderFilter.All}>Todas</MenuItem>
          <MenuItem value={OrderFilter.Completed}>Completadas</MenuItem>
          <MenuItem value={OrderFilter.Canceled}>Canceladas</MenuItem>
        </Select>
      </Stack>

      <Box sx={{ display: 'block', flexWrap: 'wrap', textAlign: 'center' }}>
        {ordersToDisplay.map((order, index) => (
          <OrderHistoryItem key={index} order={order} />
        ))}
      </Box>
      <Stack spacing={2} mt={2}>
        <Pagination
          count={Math.ceil(filteredOrders.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Stack>
    </Box>
  );
};
