import React, { useEffect } from 'react';
import chaletApi from '../../../../../api/chaletApi';
import { toast } from 'react-hot-toast';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hook';
import { OrderItem } from '../OrderItem';
import { Order } from 'types/Order';

export const InProcessOrderList = () => {
  const { user } = useAppSelector((state) => state.user);
  const [getActiveOrders, result] = chaletApi.useLazyGetActiveOrdersQuery();

  useEffect(() => {
    if (user) {
      const shop = user.works[0].shop;
      getActiveOrders(shop.id);
    }
  }, [user]);

  if (result.isError) {
    toast('Error cargando ordenes. Error');
  }
  if (result.isLoading) {
    return (
      <Box>
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }
  if (result.data) {
    const orders: Order[] = result.data;

    const sortedOrders = orders.slice().sort((a, b) => {
      return a.id - b.id;
    });

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box sx={{ display: 'block', flexWrap: 'wrap', textAlign: 'center' }}>
          <Typography variant="h4">Ordenes en proceso</Typography>
          {sortedOrders.map((order, index) => (
            <OrderItem key={index} order={order} />
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};
