import React from 'react';
import { Box, Typography } from '@mui/material';
import { OrderDetail } from 'types/Order';
import { toCurrencyFormat } from 'utils/formatter';

type OrderDetailsProps = {
  details: OrderDetail[];
};

export const OrderDetailsView: React.FC<OrderDetailsProps> = ({ details }) => {
  const groupedVariations: Record<
    number,
    Record<string, { value: string; addedValue: number }[]>
  > = {};

  details.forEach((orderDetail) => {
    if (!groupedVariations[orderDetail.id]) {
      groupedVariations[orderDetail.id] = {};
    }

    orderDetail.variations?.forEach((variation) => {
      const variationName = variation.option.variation.name;

      if (!groupedVariations[orderDetail.id][variationName]) {
        groupedVariations[orderDetail.id][variationName] = [];
      }

      groupedVariations[orderDetail.id][variationName].push(variation.option);
    });
  });

  return (
    <>
      {details.map((orderDetail) => (
        <Box display={'flex'} textAlign={'left'} key={orderDetail.id}>
          <Box flex={1}>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              {orderDetail.name} -- {toCurrencyFormat(orderDetail.price)} x{orderDetail.quantity}
            </Typography>

            {orderDetail.variations && orderDetail.variations.length > 0 && (
              <div>
                {Object.entries(groupedVariations[orderDetail.id]).map(
                  ([variationName, options]) => (
                    <Typography key={variationName} sx={{ fontSize: 12 }} color="text.secondary">
                      {variationName}:{' '}
                      {options
                        .map((option, index) => `${option.value} ($${option.addedValue})`)
                        .join(', ')}
                    </Typography>
                  )
                )}
              </div>
            )}
            <Typography sx={{ fontSize: 12 }} color={'text.secondary'} gutterBottom>
              Notas: {orderDetail.notes ? orderDetail.notes : 'Sin notas adicionales'}
            </Typography>
          </Box>
          <Box flex={0}>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              {toCurrencyFormat(orderDetail.price * orderDetail.quantity)}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};
