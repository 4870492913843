import { Paper, Typography, useTheme } from '@mui/material';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useMediaQuery } from '@mui/material';
import { amber } from '@mui/material/colors';
import { AccessTime } from '@mui/icons-material';

type props = {
  average: number;
  totalOrders: number;
  uniqueClients: number;
};

export const InformationCards: React.FC<props> = ({ average, totalOrders, uniqueClients }) => {
  const matches = useMediaQuery('(max-width:600px)');
  const { palette } = useTheme();

  return (
    <Paper
      elevation={0}
      style={{
        display: 'grid',
        gap: '24px',
        gridTemplateColumns: matches ? '1fr' : 'repeat(3, 1fr)',
        padding: '16px'
      }}>
      <Paper
        style={{
          borderRadius: 8,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: palette.primary.main,
          color: palette.white.main
        }}
        elevation={3}>
        <AccessTime style={{ fontSize: '40px', marginBottom: '8px' }} />
        <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', textAlign: 'center' }}>
          Promedio de preparación de pedidos
        </Typography>
        <Typography variant="body2">{average ? `${average} minutos` : '0 minutos'}</Typography>
      </Paper>

      <Paper
        style={{
          borderRadius: 8,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#01869E',
          color: palette.white.main
        }}
        elevation={3}>
        <FastfoodIcon style={{ fontSize: '40px', marginBottom: '8px' }} />
        <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', textAlign: 'center' }}>
          Órdenes totales
        </Typography>
        <Typography variant="body1">{totalOrders}</Typography>
      </Paper>

      <Paper
        style={{
          borderRadius: 8,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: palette.white.main,
          backgroundColor: amber[800]
        }}
        elevation={3}>
        <TrendingUpIcon style={{ fontSize: '40px', marginBottom: '8px' }} />
        <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', textAlign: 'center' }}>
          Número de clientes únicos
        </Typography>
        <Typography variant="body1">{uniqueClients}</Typography>
      </Paper>
    </Paper>
  );
};
