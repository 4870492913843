import { useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { AuthSelector } from 'ui/pages/Auth';
import { InProcessPage } from 'ui/pages/InProcess';
import { OrdersPage } from 'ui/pages/Orders';
import { AdminShop } from 'ui/pages/Admin/AdminShop';
import { AdminMenu } from 'ui/pages/Admin/AdminMenu';
import { AdminPeople } from 'ui/pages/Admin/AdminPeople';
import { ShopAdminDrawer } from 'ui/components/common/AdminDrawer';
import { ShopAdminHeader } from 'ui/components/common/AdminHeader';
import { Home } from 'ui/pages/Home';
import { useAppSelector } from 'redux/hook';
import { Login } from 'ui/pages/Login';
import { Profile } from 'ui/pages/Profile';
import chaletApi from 'api/chaletApi';
import { Dashboard } from 'ui/pages/Dashboard';

export const AppRouter = () => {
  const { userId } = useAppSelector((state) => state.auth);
  const [getUserById] = chaletApi.useLazyGetUserByIdQuery();

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId]);

  if (!userId) {
    return <Login />;
  }
  return (
    <Box>
      <ShopAdminHeader />
      <ShopAdminDrawer>
        <Container>
          <Routes>
            <Route path="/inicio" element={<Dashboard />} />
            <Route path="/en_proceso" element={<InProcessPage />} />
            <Route path="/ordenes" element={<OrdersPage />} />
            <Route path="/admin/tienda" element={<AdminShop />} />
            <Route path="/admin/menu" element={<AdminMenu />} />
            <Route path="/admin/personal" element={<AdminPeople />} />
            <Route path="/perfil" element={<Profile />} />
          </Routes>
        </Container>
      </ShopAdminDrawer>
    </Box>
  );
};
