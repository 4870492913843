import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  orderId: null
}

export const orderDetailSlice = createSlice({
  name: 'order-detail',
  initialState,
  reducers: {
    setCurrentOrder: (state, action) => {
      state.orderId = action.payload
    },
  },
})

export const {
  setCurrentOrder,
} = orderDetailSlice.actions

export default orderDetailSlice
