import logo from 'assets/ic_logo_no_title.svg'
import { Box } from '@mui/material'

type Props = {
  width?: number
  height?: number
}

export default function ChaletLogo({ width, height }: Props = { width: 100, height: 100 }) {
  return (
    <Box bgcolor="primary.main" width={150} height={150} p={4} display="flex" alignSelf="center" borderRadius={50}>
      <img src={logo} alt="logo" style={{ width, height }} />
    </Box>
  )
}
