import { Avatar, Grid, styled } from '@mui/material';

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: '4px solid #ffffff',
  width: '125px',
  height: '125px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

export const imgStyle: any = {
  aspectRatio: '16/9',
  objectFit: 'cover',
  maxWidth: '100%',
  maxHeight: '100%'
};
