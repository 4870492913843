import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fromDate: Date.now(),
  toDate: Date.now(),
}

export const salesSlice = createSlice({
  name: 'sales-slice',
  initialState,
  reducers: {
    setFromDate: (state, action) => {
      state.fromDate = action.payload
    },
    setToDate: (state, action) => {
      state.toDate = action.payload
    },
  },
})

export const {
  setFromDate,
  setToDate
} = salesSlice.actions

export default salesSlice
