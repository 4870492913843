import { Box, List, ListItem, ListItemButton, ListItemText, TextField } from '@mui/material';
import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

interface PlacesAutocompleteProps {
  setSelected: (value: any) => void;
}

export const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ setSelected }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'sv' }
    },
    debounce: 300
  });
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      setValue(description, false);
      clearSuggestions();

      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setSelected({ lat, lng });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <ListItem disablePadding key={place_id} onClick={handleSelect(suggestion)}>
          <ListItemButton>
            <ListItemText primary={main_text} secondary={secondary_text} />
          </ListItemButton>
        </ListItem>
      );
    });

  return (
    <Box>
      <TextField
        placeholder="Chalatenango, chalatenango"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        label="Ingresa tu dirección"
        fullWidth
      />

      {status === 'OK' && (
        <Box>
          <List sx={{}}>{renderSuggestions()}</List>
        </Box>
      )}
    </Box>
  );
};
