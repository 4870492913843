import { ArrowRightAlt } from '@mui/icons-material';
import { Tab, Tabs, TextField, Typography, styled, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import chaletApi from 'api/chaletApi';
import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/hook';
import { OrdersInRange } from 'ui/components/partials/Order/OrdersInRange';
import { StatisticsOrders } from 'ui/components/partials/Order/StatisticsOrders';

// Personalización del componente de pestañas
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.white.main,

    borderRadius: '5px'
  },
  backgroundColor: theme.palette.primary.main,
  marginTop: 5,
  width: '550px',
  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none', // Desactiva la transformación de texto en mayúsculas
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.common.white, // Color blanco para el texto
  '&:hover': {
    color: theme.palette.common.white
  },
  '&.Mui-selected': {
    color: theme.palette.common.white
  }
}));

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

export const OrdersPage = () => {
  const [fromDate, setFromValue] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const { user } = useAppSelector((s) => s.user);
  const [getOrdersInRange, result] = chaletApi.useLazyGetOrdersInRangeQuery();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user && fromDate && toDate) {
      const shop = user.works[0].shop;
      getOrdersInRange({
        from: fromDate,
        to: toDate,
        shopId: shop.id
      });
    }
  }, [user, fromDate, toDate]);

  return (
    <Box display="flex" flexDirection="column" justifyContent={'center'} mt={2}>
      <Typography variant="h4" textAlign={'center'}>
        Historial de Ordenes
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2} alignItems={'center'} mt={1}>
        <DatePicker
          label="Fecha inicial"
          value={fromDate}
          onChange={(newValue) => {
            setFromValue(newValue);
          }}
          slots={{
            textField: (textFieldProps) => <TextField {...textFieldProps} />
          }}
        />
        <ArrowRightAlt />
        <DatePicker
          label="Fecha final"
          value={toDate}
          onChange={(newValue) => {
            setToDate(newValue);
          }}
          slots={{
            textField: (textFieldProps) => <TextField {...textFieldProps} />
          }}
        />
      </Stack>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CustomTabs value={value} onChange={handleChange} centered>
          <CustomTab label="Ventas" />
          <CustomTab label="Estadísticas" />
        </CustomTabs>
      </Box>
      <TabPanel value={value} index={0}>
        {fromDate && toDate && result.data && <OrdersInRange orders={result.data} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {result.data && <StatisticsOrders orders={result.data} />}
      </TabPanel>
    </Box>
  );
};
