import { Box, Typography } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { Location } from 'types/Location';
import { PlacesAutocomplete } from '../PlacesAutocomplete';
import { getLatLng } from 'use-places-autocomplete';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
const librarie = ['places'];

type MapContainerProps = {
  location: Location;
  editMode?: boolean;
  getCoordinates?: (coordinates: any) => void;
};

const containerStyle = {
  marginTop: '15px',
  display: 'flex',
  height: '300px'
};

export const MapContainer: React.FC<MapContainerProps> = ({
  location,
  editMode,
  getCoordinates
}) => {
  const [selected, setSelected] = useState<google.maps.LatLngLiteral | null>(null);

  useEffect(() => {
    if (location) {
      setSelected({
        lat: location.coordinates[1],
        lng: location.coordinates[0]
      });
    }
  }, [location]);

  useEffect(() => {
    if (selected && getCoordinates && editMode) {
      getCoordinates(selected);
    }
  }, [selected]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: librarie as any
  });

  const handleMarkerDrag = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setSelected({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  };

  if (loadError) return <div>Error loading Google Maps: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      {isLoaded && editMode && (
        <Box>
          <PlacesAutocomplete setSelected={setSelected} />
          <Typography variant="caption" color={'red'} textAlign={'center'}>
            Nota: Mueve el marcador para ajustar la dirección de la tienda
          </Typography>
        </Box>
      )}
      {selected && (
        <GoogleMap
          zoom={16}
          mapContainerStyle={containerStyle}
          center={selected}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false
          }}>
          {editMode && (
            <MarkerF
              position={selected}
              draggable={editMode}
              onDragEnd={handleMarkerDrag}></MarkerF>
          )}
          {!editMode && <MarkerF position={selected}></MarkerF>}
        </GoogleMap>
      )}
    </>
  );
};
