/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import chaletApi from '../api/chaletApi';
import { Shop } from 'types/Shop';
import { User } from 'types/User';

type UserState = {
  user: any | null;
  selectedShop: Shop | null;
};

const initialState: UserState = {
  user: null,
  selectedShop: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedShop: (state, action: PayloadAction<Shop>) => {
      state.selectedShop = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.login.matchFulfilled,
      (state, action: PayloadAction<{ success: boolean; data: { user: any } }>) => {
        if (action.payload.success) {
          state.user = action.payload.data.user;
        }
      }
    );
    builder.addMatcher(
      chaletApi.endpoints.getUserById.matchFulfilled,
      (state, action: PayloadAction<{ success: boolean; data: User }>) => {
        if (action.payload) {
          const user = action.payload.data
          state.user = user
          if (user.works.length > 0) {
            state.selectedShop = user.works[0].shop
          }
        }
      }
    );
  }
});

export const { setSelectedShop } = userSlice.actions;
export default userSlice

