import { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Paper,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
  TextField,
  styled,
  useTheme,
  Box,
  IconButton
} from '@mui/material';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomAvatar, imgStyle } from './style';

import chaletApi from 'api/chaletApi';
import { openEditUserModal, closeEditUserModal } from 'redux/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { pictureValidation } from 'utils/pictureValidation';
import { CloudUpload } from '@mui/icons-material';
import { formatDate } from 'utils/formatter';
import 'yup-phone-lite';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const schema = yup.object().shape({
  name: yup.string().required('El nombre es obligatorio'),
  username: yup.string().required('El nombre de usuario es obligatorio'),
  email: yup
    .string()
    .email('Correo electrónico no válido')
    .required('El correo electrónico es obligatorio'),
  phone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(9, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  birthDate: yup
    .string()
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Formato de fecha no válido (mm-dd-aaaa)')
    .required('La fecha de nacimiento es obligatoria')
});

export const Profile = () => {
  const dispatch = useAppDispatch();
  const { isEditUserModalOpen } = useAppSelector((state) => state.modalSlice);
  const { user } = useAppSelector((state) => state.user);
  const [updateUser, result] = chaletApi.useUpdateUserMutation();

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    dispatch(closeEditUserModal());
  }, [dispatch]);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    phone: '',
    birthDate: ''
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleChangeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      pictureValidation(file, setProfileImage, setPreviewProfileImage);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        username: user.username || '',
        email: user.email || '',
        phone: user.phone || '',
        birthDate: user.birthDate || ''
      });
    }
  }, [user]);

  useEffect(() => {
    if (!isEditUserModalOpen && result.isError) {
      toast('Error guardando usuario');
    }

    if (!isEditUserModalOpen && result.isSuccess) {
      handleClose();
    }
  }, [result, handleClose, isEditUserModalOpen]);

  const saveUser = async (data: any) => {
    const userId = user.id;
    const { name, username, email, phone } = data;

    const formattedBirthDate = formatDate(data.birthDate);

    if (profileImage) {
      const saveFormData = new FormData();

      saveFormData.append('name', name);
      saveFormData.append('username', username);
      saveFormData.append('email', email);
      saveFormData.append('phone', phone);
      saveFormData.append('profileImage', profileImage);
      saveFormData.append('birthDate', formattedBirthDate);

      await updateUser({ userId, body: saveFormData })
        .unwrap()
        .then((payload) => {
          console.log(payload);
          if (payload.success) {
            toast.success('Usuario actualizado');
            handleClose();
          } else {
            toast.error('ha ocurrido un error, verifica tus datos');
          }
        })
        .catch((error) => {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        });
    } else {
      await updateUser({ userId, body: data })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            toast.success('Usuario actualizado');
            handleClose();
          } else {
            toast.error('ha ocurrido un error, verifica tus datos');
          }
        })
        .catch((error) => {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        });
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="80vh">
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            textAlign: 'center',
            borderRadius: '12px',
            margin: 'auto',
            maxWidth: '400px'
          }}>
          <Avatar
            src={user?.profileImage}
            alt={user?.name}
            sx={{ width: '120px', height: '120px', marginBottom: '10px', margin: 'auto' }}
          />
          <Typography variant="h4" marginTop={'5%'}>
            {user?.name}
          </Typography>
          <Table sx={{ width: '100%' }}>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Usuario:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.username}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Email:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.email}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Teléfono:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.phone}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Fecha de nacimiento:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    {formatDate(user?.birthDate)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {user?.Shops && user.Shops.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                Tienda
              </Typography>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{ margin: '16px 0' }}>
                <Avatar
                  src={user.Shops[0].profileImage}
                  alt={user.Shops[0].name}
                  sx={{ width: '60px', height: '60px', marginRight: '16px' }}
                />
                <Typography variant="subtitle1" style={{ fontSize: '1.2em' }}>
                  {user.Shops[0].name}
                </Typography>
              </Grid>
            </>
          )}
          <Button variant="contained" color="primary" onClick={() => dispatch(openEditUserModal())}>
            Actualizar Perfil
          </Button>
          <Modal open={isEditUserModalOpen} onClose={handleClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 8,
                textAlign: 'center'
              }}>
              <form onSubmit={handleSubmit(saveUser)}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Actualizar Perfil
                </Typography>

                <CustomAvatar
                  src={previewProfileImage ? previewProfileImage : user?.profileImage}
                />
                <IconButton
                  sx={{
                    marginTop: '-50%',
                    backgroundColor: theme.palette.white.main,
                    '&:hover': {
                      backgroundColor: `rgba(128, 128, 128, 0.7)`
                    }
                  }}
                  component="label"
                  color="primary">
                  <CloudUpload />
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={handleChangeProfileImage}
                  />
                </IconButton>
                <TextField
                  label="Nombre"
                  fullWidth
                  {...register('name')}
                  defaultValue={formData.name}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Nombre de Usuario"
                  fullWidth
                  {...register('username')}
                  defaultValue={formData.username}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Correo Electrónico"
                  fullWidth
                  {...register('email')}
                  defaultValue={formData.email}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Teléfono"
                  fullWidth
                  {...register('phone')}
                  defaultValue={formData.phone}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Fecha de Nacimiento (mm-dd-aaaa)"
                  fullWidth
                  type="text"
                  {...register('birthDate')}
                  defaultValue={formatDate(formData.birthDate)}
                  placeholder="mm-dd-aaaa"
                  sx={{ marginBottom: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2, marginRight: 2 }}>
                  Guardar Cambios
                </Button>
                <Button variant="outlined" onClick={handleClose} sx={{ marginTop: 2 }}>
                  Cerrar
                </Button>
              </form>
            </Box>
          </Modal>
        </Paper>
      </Grid>
    </Grid>
  );
};
