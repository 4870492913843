import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ProductVariation } from 'types/Order';
import { toCurrencyFormat } from 'utils/formatter';
import { AddVariationForm } from '../AddVariationForm';
import { AddVariationOptionForm } from '../AddVariationOptionForm';

type VariationCardProps = {
  variation: ProductVariation;
};

export const VariationCard: React.FC<VariationCardProps> = ({ variation }) => {
  const [isAddingOption, setIsAddingOption] = useState(false);
  const [optionId, setOptionId] = useState<number | null>(null);
  const [isEditingVariation, setIsEditingVariation] = useState(false);
  const [deleteProductVariation] = chaletApi.useDeleteProductVariationMutation();
  const [deleteProductVariationOption] = chaletApi.useDeleteProductVariationOptionMutation();

  const handleAddOption = useCallback(() => {
    setIsAddingOption(true);
  }, []);

  const handleEditOption = (optionId: number) => {
    setOptionId(optionId);
  };

  const deleteVariation = async () => {
    await deleteProductVariation(variation.id)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          toast.success('Variacion eliminada con éxito');
        } else {
          toast.error('Ha ocurrido un error');
        }
      })
      .catch((err) => {
        toast.error('No puedes eliminar una variación si contiene opciones dentro');
      });
  };


  const deleteVariationOption = async (id: number) => {
    await deleteProductVariationOption(id)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          toast.success('Variacion eliminada con éxito');
        } else {
          toast.error('Ha ocurrido un error');
        }
      })
      .catch((err) => {
        toast.error('No puedes eliminar una variación si contiene opciones dentro');
      });
  };

  return (
    <Box>
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontWeight: 'bold' }}>{variation.name}</Typography>

          <Stack direction={'row'} alignItems={'center'}>
            <IconButton aria-label="edit" color="info" onClick={() => setIsEditingVariation(true)}>
              <Edit />
            </IconButton>
            <IconButton aria-label="delete" color="error" onClick={deleteVariation}>
              <Delete />
            </IconButton>
            <Typography sx={{ fontWeight: 'bold' }}>
              {variation.required ? 'Requerido' : 'Opcional'}
            </Typography>
          </Stack>
        </Box>
        <Box>
          {isEditingVariation && (
            <AddVariationForm
              productId={variation.productId}
              variation={variation}
              onClose={() => setIsEditingVariation(false)}
            />
          )}
        </Box>

        <List sx={{ width: '100%', padding: 1 }}>
          {variation.options?.map((variationOption) => (
            <Box key={variationOption.id}>
              {optionId === variationOption.id ? (
                <AddVariationOptionForm
                  variationOption={variationOption}
                  variationId={variation.id}
                  onClose={() => setOptionId(null)}
                />
              ) : (
                <ListItem
                  key={variationOption.id}
                  style={{ padding: 0, margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  secondaryAction={
                    <>
                      <IconButton
                        edge="start"
                        aria-label="edit"
                        onClick={() => handleEditOption(variationOption.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => deleteVariationOption(variationOption.id)}>
                        <Delete />
                      </IconButton>
                    </>
                  }>
                  <ListItemText
                    style={{ padding: 0, margin: 0, paddingTop: 0, paddingBottom: 0 }}
                    primary={variationOption.value}
                    secondary={toCurrencyFormat(variationOption.addedValue)}
                  />
                </ListItem>
              )}
            </Box>
          ))}
        </List>

        {isAddingOption ? (
          <AddVariationOptionForm
          correlativeVariation={variation.options ? variation.options.length + 1 : 1}
            variationId={variation.id}
            onClose={() => setIsAddingOption(false)}
          />
        ) : (
          <Box>
            <Button variant="outlined" color='secondary' onClick={handleAddOption}>
              Agregar opción
            </Button>
          </Box>
        )}
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </Box>
  );
};
