import './App.css';
import { Toaster } from 'react-hot-toast';

import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import RootProvider from './providers/RootProvider'
import { ThemeConfig } from 'ui/config/theme.config';
import { AppRouter } from 'routes';

const queryClient = new QueryClient();

function App() {
  return (
    <Box display="flex">
      <RootProvider>
        <QueryClientProvider client={queryClient}>
            <ThemeConfig>
              <BrowserRouter>
                <Box display="flex" flexDirection="column" flex={1}>
                 <AppRouter/>
                </Box>
              </BrowserRouter>
            </ThemeConfig>
        </QueryClientProvider>
      </RootProvider>
    </Box>
  );
}

export default App;
