import { configureStore } from '@reduxjs/toolkit';
import chaletApi from '../api/chaletApi';
import authSlice from './authSlice';
import orderDetailSlice from './orderDetailSlice';
import salesSlice from './salesSlice';
import userSlice from './userSlice';
import modalSlice from './modalSlice';
import shopAdminDrawer from './shopDrawer';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import persistedUserAuthSlice from './authSlice';

const middleware = [thunk, chaletApi.middleware];

export const store = configureStore({
  reducer: {
    auth: persistedUserAuthSlice,
    user: userSlice.reducer,
    orderDetail: orderDetailSlice.reducer,
    sales: salesSlice.reducer,
    shopAdminDrawer: shopAdminDrawer.reducer,
    modalSlice: modalSlice.reducer,
    [chaletApi.reducerPath]: chaletApi.reducer
  },
  middleware: middleware
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
